<template>
  <div>
    <div class="lp-divider-main" style="padding-top:96px"></div>
    <div class="padding-top-16 flex-align">
      <div class="body0-bold main">케어 서비스</div>
      <div class="body4 error margin-left-12">*이슈 해결 비용 별도</div>
    </div>
    <div class="body2 sub3">가입한 케어 서비스 단계별로 서비스에서 발생하는 이슈 대응 시간/문의 접수 창구에 차이가 있습니다.</div>
    <div class="lp-divider-gray2 padding-top-16"></div>

    <div class="card-wrapper margin-top-32">
      <div class="card-info unselect"
           :class="{'selected-card': inputValue && item.id === inputValue.id}"
           v-for="item in list"
           :key="`hosting-${item.id}`"
           @click="selectItem(item)">
        <div class="flex-align">
          <i class="material-icons gray" :class="{'selected': inputValue && item.id === inputValue.id}">
            {{ inputValue && item.id === inputValue.id ? 'radio_button_checked' : 'radio_button_unchecked' }}</i>
          <div class="body2 margin-left-8">{{ item.name }}</div>
        </div>
        <div class="margin-top-8">
          <span class="h6 main">{{ item.price | currencyNum }}</span>
          <span class="body4 main margin-left-4">원</span>
          <span class="body4 sub3">/</span>
          <span class="body4 sub3 margin-left-4">월</span>
        </div>
        <div class="flex margin-top-12">
          <div class="body4-bold sub" style="width:55px">진행방법</div>
          <div class="body4 sub margin-left-16">{{ item.channel }}</div>
        </div>
        <div class="lp-divider-gray2 margin-top-16 margin-bottom-24"></div>
        <div class="card-issue-wrapper">
          <div class="text-center">
            <div class="subtitle6 sub3 margin-bottom-12">프론트엔드 관련 이슈</div>
            <div class="subtitle6 sub2">
              최대<span class="primary margin-left-4">{{ item.front_issue }}</span>일 내 답변
            </div>
          </div>
          <div class="text-center">
            <div class="subtitle6 sub3 margin-bottom-12">서버, 결제 관련 이슈</div>
            <div class="subtitle6 sub2">
              최대<span class="primary margin-left-4">{{ item.server_issue }}</span>시간 내 답변
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SelectCareService",
    props: {
      value: {
        type: Object
      }
    },
    created() {
      this.getData();
    },
    data() {
      return {
        list: [],
        inputValue: undefined
      }
    },
    methods: {
      selectItem(item) {
        this.inputValue = item;
        this.$emit('update:value', this.inputValue);
      },
      getData() {
        this.$axios.get('public/launchpack/care_service').then(res=>{
          this.list = res.data;
          if(this.list.length>0) this.inputValue = this.list[0];
          this.$emit('update:value', this.list[0]);
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(2, 1fr)
    .card-info
      border 1px solid $gray1
      padding 32px
      border-radius 8px

  .mobile
    .card-info
      border 1px solid $gray1
      padding 24px
      border-radius 8px
      margin-top 24px

  .introduction-content
    white-space pre-line

  .selected
    color $primary

  .card-issue-wrapper
    display grid
    grid-gap 12px
    grid-template-columns repeat(2, 1fr)

  .selected-card
    border 2px solid $primary !important

</style>
